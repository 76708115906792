import styled from "styled-components";

export const Container = styled.div`
    max-height: 820px;
`

export const AffiliationsContainer = styled.div`
    height: 320px;
    background-color: #E6AB99;
    padding: 20px;
`

export const AffiliationsHeader = styled.h1`
    color: #ffffff;
    text-align: center;
    padding: 20px;

`

export const AffiliationsP = styled.p`
    color: #ffffff;
    font-size: 14px;
    
    text-align: center;

`

export const AffiliationsImages = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    text-align: center;
    place-items: center;
    padding: 20px;
`

export const AffiliationImg = styled.img`
    max-height: 80px;
    max-width: 60%;

    

`