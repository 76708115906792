import styled from 'styled-components'

import {Link} from 'react-router-dom'



export const FooterContainer = styled.footer`
    background-color: #4E8395;

`

export const FooterWrap = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 1100px;
    margin: 0 auto;
    

`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;
    justify-self: center;

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.div`
    font-size: 14px;
    margin-bottom: 16px;

`

export const FooterLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: #E6AB99;
        transition: 0.3s ease-out; 
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }

`

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
    justify-content: space-between;


` 

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;

`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;


`
export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
`

export const StyledContactForm = styled.div`
width: 400px;
form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #E6AB99;
    &:focus {
      border: 2px solid #E6AB99;
    }
  }
  textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 80px;
    min-height: 80px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #E6AB99;
    &:focus {
      border: 2px solid #E6AB99;
    }
  }
  label {
    margin-top: 1rem;
    color: #fff;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  input[type="submit"] {
    margin-top: 2rem;
    cursor: pointer;
    background: #E6AB99;
    color: white;
    border: none;
  }
}
`;

export const SocialClaim = styled.div `
    font-size: 11px;
    justify-self: flex-start;
    display: flex;
    max-width: 250px;
    padding-top: 20px;
    
`
