import React from 'react'

const SignInPage = () => {
  return (
    <div>
        <h1>
            Sign In Page
        </h1>
        
    </div>
  )
}

export default SignInPage