import React from 'react'
import { FaDirections } from 'react-icons/fa'
import { Button } from '../ButtonElement'
import {InfoWrapper, InfoContainer, 
InfoRow, 
Column1, 
Column2, 
TextWrapper, 
TopLine, 
Heading, SubTitle, BtnWrap, ImgWrap, Img, Buttons
} from './InfoElements'




export const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, img, alt, primary, dark, dark2, willBeDirected }) => {
  return (
    <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine lightBg={lightBg}>
                                {topLine}
                            </TopLine>
                            <Heading lightText={lightText}>
                                {headline}
                            </Heading>
                            <SubTitle darkText={darkText}>
                                {description} 
                            </SubTitle>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} type='video/mp4' alt={alt} >
                            </Img>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default InfoSection