import { render } from '@testing-library/react'
import styled from 'styled-components'

export const ServicesContainer = styled.div`
    /* max-height: 100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background : #ffffff;

    @media screen and (max-width: 768px) {
        height: 1100px;

    }

    @media screen and (max-width: 480px) {
        height: 1300px;

    }
`

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    margin: 2.5%;
    

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;

    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        padding: 0 20px;
    }



`


export const ServicesCard = (props) => {

   

    return <>
    <ServicesCardDiv onClick={()=>{props.onClick(props.description)}}>     
                
        <h1>{props.name}</h1>
        <img width={100} height={100} src={props.src} alt={props.name}></img>

    </ServicesCardDiv>
    </>
}

const ServicesCardDiv = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    color: #E6AB99;
    padding: 5px;
    border: 2px solid #4E8395;

    &:hover {
        color: #4E8395;
        background-color: #E6AB99;
        cursor: pointer;
    }
`

export const ServicesIcon = (props) => {

    return (
        <img src={props.src} height="60px" />

    )
}

export const ServicesH1 = styled.h1`
    font-size: 3vw;
    padding-top: 5%;
    color: #E6AB99;
    margin-bottom: 0 auto;

    @media screen and (max-width: 480px) {
        font-size: 8vw;
        padding-top: 5%;

    }
    &:hover {
        color: #4E8395;
        cursor: pointer;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 1.5vw;
    margin-bottom: 10px;
    color: #4E8395;
    text-transform: uppercase;
    max-width: 80%;
    text-align: center;
    padding: 20px;
    max-height: 70%;

    @media screen and (max-width: 480px) {
        font-size: 4vw;
        padding: 2px;

    }

`

export const ServicesP = styled.p`
    font-size: 1.5vw;
    text-align: center;
    color: #4E8395;
    padding: 5%;
    max-width: 70%;

    @media screen and (max-width: 480px) {
        font-size: 4vw;
        margin: 5%;

    }

`