import React from 'react'
import Footer from '../footer'

import { Container, AffiliationImg, AffiliationsContainer, AffiliationsHeader, AffiliationsImages, AffiliationsP } from './AffiliationElements'

const Affiliations = ({id}) => {
  return <>
  <Container>
    
  
    <AffiliationsContainer id={id}>
        <AffiliationsHeader>
            Affiliations and Awards.
        </AffiliationsHeader>
        <AffiliationsP>
            Swyft Finance, is a proud member, recepient or advocate for the following industry bodies. 
        </AffiliationsP>
        <AffiliationsImages>
            <AffiliationImg src={require('../../images/fbaa_transparent.png')}>

            </AffiliationImg>
            <AffiliationImg src={require('../../images/afca_transparent.png')}>

            </AffiliationImg>
            <AffiliationImg src={require('../../images/connective_transparent.png')}>

            </AffiliationImg>
            <AffiliationImg src={require('../../images/transparent_award.png')}>

            </AffiliationImg>
        </AffiliationsImages>
         
    </AffiliationsContainer>
    <Footer />   
    </Container>
    </>
}

export default Affiliations
