import React from 'react'
import { SideBarContainer, 
        Icon, 
        CloseIcon, 
        SideBarWrapper, 
        SidebarMenu,
        SideBtnWrap,
        SidebarRoute,
        SidebarLink  } from './SidebarElements'

const SideBar = ({ isOpen, toggle }) => {
  return (
    <>
       <SideBarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon /> 

            </Icon>
            <SideBarWrapper> 
                <SidebarMenu>
                    <SidebarLink to='consumeraf' onClick={toggle}>
                    Consumer Asset <br></br> Finance
                    </SidebarLink>
                    <SidebarLink to='commercialaf' onClick={toggle}>
                    Commercial Asset <br></br> Finance
                    </SidebarLink>
                    <SidebarLink to='commercialuf' onClick={toggle}>
                    Unsecured Comercial <br></br> Finance
                    </SidebarLink>
                    <SidebarLink to='consumeruf' onClick={toggle}>
                    Personal Loans
                    </SidebarLink>
                    <SidebarLink to='partners' onClick={toggle}>
                    Swyft Partners™
                    </SidebarLink>

                    <SidebarLink to='recognition' onClick={toggle}>
                     About us & Contact. 
                    </SidebarLink>
                    
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to="signin">
                        Sign In
                    </SidebarRoute>
                </SideBtnWrap>
            </SideBarWrapper>
        </SideBarContainer> 
    </>
  )
}

export default SideBar