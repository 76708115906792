export const homeObjOne = {
    id: 'consumeraf',
    lightBg: true, 
    lightText: false, 
    lightTextDesc: false, 
    topLine: 'Consumer Asset Finance', 
    headline: 'Cars, bikes, jetskis, boats, you name it.',
    description: `The right loan structure can make all the difference, when it comes to your financial health. We work with the big banks, all the way down to the lesser known, and nonetheless of your credit score, work or residential situation, we can find you a solution, or advise you on how to get there.`,
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/consumerasset.svg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
    

}

export const homeObjTwo = {
    id: 'commercialaf',
    lightBg: false, 
    lightText: true, 
    lightTextDesc: true, 
    topLine: 'Commercial Asset Finance', 
    headline: ' Whether it is a new company car, truck or ute or even a new fit - out for that dream office.',
    description: `From the smallest of machines, to massive industrial scale pieces we have your finance covered. We have assisted 1000's and counting of small Aussie businesses that have been able to capitalize their business' and we look forward to lending our hand to you too. (Pun intended).
    `,
    buttonLabel: 'Start Now',
    imgStart: false ,
    img: require('../../images/commercialasset.svg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: false,
    willBeDirected: 'https://github.com/SauraPG72/Trekka'
}

export const homeObjThree = {
    id: 'commercialuf',
    lightBg: true, 
    lightText: false, 
    lightTextDesc: false, 
    topLine: 'Other Commercial Funding.', 
    headline: 'Term loans, overdrafts, factor finance and more.',
    description: `Cash-flow shortage? New inventory purchase, new marketing campaign? Or is it bigger, like you want to buy out a competitor? We work with Australia's top accountants and business advisers on these type of cases, and we look forward to helping you too.`,
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/commericalunsecured.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true,
    willBeDirected: 'https://github.com/SauraPG72/ProjectThree'

}

export const homeObjFour = {
    id: 'consumeruf',
    lightBg: false, 
    lightText: true, 
    lightTextDesc: true, 
    topLine: 'Unsecured Personal Loans.', 
    headline: "Sometimes, we all need a little extra cash in our back pocket, and a “fast little loan” or credit card just won't cut it.",
    description: `We only deal with regulated, and accredited financial institutions, and will read the fine print with you, so when it comes time to renovate the pergola, or take that much needed holiday, you can have the cash without worry.`,
    buttonLabel: 'Start Now',
    imgStart: false ,
    img: require('../../images/consumerunsecured.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    willBeDirected: 'https://github.com/SauraPG72/RMS'

}