import React from 'react'

import { useState } from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesIcon, ServicesH2, ServicesP, ServicesCard } from './ServicesElements'

const Services = ({id}) => {

 const [description, setDescription] = useState('')


  return (
    <>
        <ServicesContainer id={id} >
            <ServicesH1 onClick={()=>{setDescription('')}}>
                Swyft Partners™.
            </ServicesH1>
            <ServicesH2>
                Join the 100's of parnters, that have chosen to partner with us and improve their client experience using our platform. <br></br> Do you fit into one of these categories?
            </ServicesH2>
            <ServicesWrapper>

                <ServicesCard  name="Mortgage Brokers" 
                src={require('../../images/mortgage_broker.svg').default}
                description="Did you know 67% of Australian's buy a motor vehicle 3 months after buying their first car. Our use cases involve using personal loans to consolidate debt, adding more value to existing clients by assisting them with asset financing and improving your lifetime customer value."
                onClick={(value) => {setDescription(value)}}
                >
                </ServicesCard>

                <ServicesCard  name="Accountants" 
                src={require('../../images/accountant.svg').default}
                description="Capitalising a business with new assets, may not only act as a great tax-minimisation strategy, but may help your client's business gain a competitive advantage. Adding value to your clientele with asset financing or commercial funding will not only add to your bottom line but will also help you add more value to your customers. It's a win/win. "
                onClick={(value) => {setDescription(value)}}
                >
                </ServicesCard>

                <ServicesCard  name="Financial Planners" 
                src={require('../../images/financial_planner.svg').default} 
                description="Working to improve your client's financial position is an endeavour that requires as many tools as possible. Finding the right leverage options can become increasingly difficult and having access to an opinion in smaller ticket financing may just be the answer. Become a Swyft Partner™ and never look back. "
                onClick={(value) => {setDescription(value)}}
                >
                </ServicesCard>

                <ServicesCard  name="Asset Dealers" 
                src={require('../../images/asset_dealer.svg').default}
                description="If it's something that requires a bit more than a credit card limit, we can help finance it. Whether you are selling new or used, for personal or business and even if it's highly specialised our 60+ lender network never fails to have an answer. Our responsive team, accessible 7 days a week and we can help move your stock faster than ever. "
                onClick={(value) => {setDescription(value)}}
                >
                </ServicesCard>

                <ServicesCard  name="Subcontractor Networks" 
                src={require('../../images/asset_dealer.svg').default} 
                description="New van drivers, truck drivers, delivery professionals, electricians, cleaning franchisees and more. We work with some of Australia's largest subcontractor networks, and often they come across incredible talent that just aren't equipped. We have great funding solutions for start-up ABN's, and can help you grow your contractor business. "
                onClick={(value) => {setDescription(value)}}
                >
                </ServicesCard>

                <ServicesCard  name="Affiliate Marketers" 
                src={require('../../images/affiliate_marketing.svg').default}
                description="Blogs, social media superstars, instagram theme pages, finance influencers, comparison sites and more. If you have an audience yearning for advice in smaller ticket financing, look no further in finding them a solution. We have great technology for you to utilise, and market leading affiliate programs."
                onClick={(value) => {setDescription(value)}}
                >
                </ServicesCard>

            </ServicesWrapper>
            <ServicesP>
                {description}
            </ServicesP>
        </ServicesContainer>
    </>
  )
}

export default Services




