import React, { useState } from 'react'
import Video from '../../videos/video1.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, ArrowForward, ArrowRight, HeroBtnWrapper  } from './HeroElements'
import { Button } from '../ButtonElement'

const HeroSection = () => {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }


  return (
    <HeroContainer>
        <HeroBg>
            <VideoBg autoPlay playsInline loop muted src={Video} type='video/mp4' />


        </HeroBg>
        <HeroContent>
            <HeroH1>Simplifying finance for Australian professionals.</HeroH1>
            <HeroP>
                We're an award winning fintech, and Australia's leading smaller ticket finance introducer.
            </HeroP>
            <HeroBtnWrapper>
                <Button to='footer' onMouseEnter={onHover} onMouseLeave={onHover}
                primary="true"
                dark="true"
                >
                     Contact Us {hover ? <ArrowForward /> : <ArrowRight/>}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection